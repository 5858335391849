import React from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import "./partners.scss";
import { Card } from 'antd';



const partners = () => {


    return (
        <div className="">
            <Header />
            <div className="partners-main-container">

                <section className="partner-hero-section">
                    <div className="hero-content">
                        <h1>Our dedicated partners bring together a dynamic team of motivated individuals with diverse expertise across various fields</h1>
                        <p>Brand Identity, Customer Management, Booking Management, Operations, Websites, and Listing Platform Integrations—All in One Place!</p>
                    </div>
                </section>
                <section className="partners-content-section">
                    <div className="cards-container">
                        <Card className="card">
                            <p>Brand Strategists</p>
                        </Card>
                        <Card className="card">
                            <p>UX Designers</p>
                        </Card>
                        <Card className="card">
                            <p>Website Builders</p>
                        </Card>
                        <Card className="card">
                            <p>Product Development</p>
                        </Card>
                        <Card className="card">
                            <p>Multi Media & Virtual Reality</p>
                        </Card>
                    </div>
                </section>
            </div>
            <Footer />

        </div>
    );
}

export default partners;
