import "./App.css";
import Routers from "./routers/router";

function App() {


  return (
    <div className="App">
      <Routers />
    </div>
  );
}

export default App;
