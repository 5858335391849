import React from "react";
import "./footer.scss";
import { NavLink } from "react-router-dom";

const Footer = () => {

    return (
        <div className="footer-menu">
          
            <div className="cprt-text">
                <p>@2024 ImagineLoom, All rights reserved</p>
            </div>
            <ul className="">
                <li>
                    <NavLink
                        to="mailto: connect@imagineloom.com"
                        activeclassname="active"
                        className="contact-link"
                    >
                        Get in touch
                    </NavLink>
                </li>
            </ul>
        </div>
    );
};
export default Footer;
