import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../home/home.js";
import Partners from "../partners/partners.js";
import Products from "../products/products.js";








const Routers = () => {
    return (
        <div>
            <BrowserRouter basename="/">
                <Routes>
                    <Route exact path="/" element={<Home/>} />
                    <Route exact path="/partners" element={<Partners />} />
                    <Route exact path="/products" element={<Products />} />


                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default Routers;
