import React from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import "./products.scss";
import { Card } from 'antd';




const products = () => {


    return (
        <div className="">
            <Header />
            <section className="products-hero-section">
                <div className="hero-content">
                    <h1>Introducing Digital Infrastructure for <br />your business</h1>
                    <p>Brand Identity, Customer Management, Booking Management, Operations, Websites, and Listing Platform Integrations—All in One Place!</p>
                </div>
            </section>
            <section className="products-content-section">
                {/* Row-1 */}
                <div className="content-row">
                    <h1>Hospitality</h1>
                    <div className="cards-container">
                        <Card hoverable className="card">
                            <p>Resort</p>
                        </Card>
                        <Card hoverable className="card">
                            <p>Home Stays</p>
                        </Card>
                    </div>
                </div>
                {/* Row-2 */}
                <div className="content-row">
                    <h1>Travel and Experiences</h1>
                    <div className="cards-container">
                        <Card hoverable className="card">
                            <p>Small Tours</p>
                        </Card>
                        <Card hoverable className="card">
                            <p>Travel Agengy</p>
                        </Card>
                    </div>
                </div>

                {/* Row-3 */}
                <div className="content-row">
                    <h1>Events and Venues</h1>
                    <div className="cards-container">
                        <Card hoverable className="card">
                            <p>Wedding Venues</p>
                        </Card>
                        <Card hoverable className="card">
                            <p>Event Management</p>
                        </Card>
                    </div>
                </div>

            </section>
            <Footer />

        </div>
    );
}

export default products;
