import React from "react";
import "./header.scss";
import { NavLink } from "react-router-dom";
import AppLogo from "../assets/app-logo.svg";

const Header = () => {

    return (
        <div className="header-menu">
            <ul className="">
                <li>
                    <NavLink to="/" activeclassname="active" className="menu-links">
                        Home
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/partners"
                        activeclassname="active"
                        className="menu-links"
                    >
                        Partners
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/products"
                        activeclassname="active"
                        className="menu-links"
                    >
                        Products
                    </NavLink>
                </li>

            </ul>
            <div className="app-logo">
                <img src={AppLogo} alt="This is App Logo"/>
            </div>
            <ul className="">
                <li>
                    <NavLink
                        to="mailto: connect@imagineloom.com"
                        activeclassname="active"
                        className="menu-links contact-link"
                    >
                        Get in touch
                    </NavLink>
                </li>
            </ul>
        </div>
    );
};
export default Header;
