import React from "react";
import "./home.scss";
import Header from "../header/header";
import Footer from "../footer/footer";
import { Carousel } from 'antd';


const home = () => {

    return (
        <div className="">
            <Header />
            <section className="home-hero-section">
                <div className="hero-content">
                    <h1>We Craft Your Imagination into Reality</h1>
                    <p>We help founders build impactful businesses and develop innovative products from the ground up. With tailored support in digital presence, branding, operations, and product development, we work alongside you to ensure your vision into reality</p>
                </div>
            </section>
            <section className="home-cs-section">
                <Carousel autoplay>
                    <div>
                        <div className="contentStyle">
                            <span className="cs-badge">Hospitality</span>
                            <h2 className="cs-title">Sportico</h2>
                            <p className="cs-body">Surrounded by scenic beauty and attractive tourist attractions we make it convenient for you to visit all the beautiful places with our inhouse guide. We can also help you arrange transport facilities for an easier commute to nearby places</p>
                        </div>
                    </div>
                    <div>
                        <div className="contentStyle">
                            <span className="cs-badge">Events & Venues</span>
                            <h2 className="cs-title">Elegance</h2>
                            <p className="cs-body">Specializing in unforgettable experiences, this venue offers versatile spaces and a dedicated team, ensuring luxury and elegance for weddings and special events</p>
                        </div>
                    </div>
                    <div>
                        <div className="contentStyle"> <span className="cs-badge">Pharma</span>
                            <h2 className="cs-title">SNP Code</h2>
                            <p className="cs-body">Empowers genomic innovation with advanced analysis and strategic insights, unlocking the full potential of genomic data</p></div>
                    </div>
                </Carousel>
            </section>

            <Footer />
        </div>
    );
}

export default home;
